
.start-button{
    height: 30px;
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:18px;
    color:white;
    font-style:italic;
    padding: 5px 25px 5px 15px;
    text-shadow:1px 1px 1px #333;
    border-radius:0px 10px 15px 0px;
    border:none;
    transform:skewX(-3deg);
    
    box-shadow: 0px 5px 10px #79ce71 inset;
    background: radial-gradient(circle, #5eac56 0%, #3c873c 100%) center/cover no-repeat;
}

.icon {
    background: url('http://opi.wiki/pic?w=6&f=108883&e=2&o=703') center/cover no-repeat;
    position:relative;
    display:inline-block;
    height:15px;
    width:15px;
    top:1px;
    margin-right: 5px;
    transform:skewX(3deg);
    -webkit-filter:drop-shadow(1px 1px 1px #333);
}

.start-button:hover{
     box-shadow:0px 0px 15px #333 inset !important;
}