.mb10{
    margin-bottom: 10px;
}

.Coins{
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.Coins .container{
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 5px 2px 5px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Coins input{
    border: 2px dashed black;
}

.Coins h1{
    font-family: 'Lucida Console', monospace;
    font-size: 28px;
    line-height: 27px;
}

.Coins h2{
    font-family: 'Lucida Console', monospace;
    font-size: 20px;
    line-height: 21px;
}

.Coins h3{
    font-family: 'Lucida Console', monospace;
    font-size: 17px;
    line-height: 18px;
}

.Coins h4{
    font-family: 'Lucida Console', monospace;
    font-size: 15px;
    line-height: 16px;
}

.Coins h5{
    font-family: 'Lucida Console', monospace;
    font-size: 13px;
    line-height: 14px;
}

.Coins p{
    font-family: 'Lucida Console', monospace;
    font-size: 13px;
    line-height: 14px;
}


.dex-link{
    font-family: 'Lucida Console', monospace;
    font-size: 15px;
    line-height: 16px;
    color: black;
    font-weight: 500;
}

.title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.title-container h1{
    margin-bottom: 10px;
}

.body-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.single-box{
    display: flex;
    align-items: center;
}

.single-box h3{
    margin-right: 3px;
}
