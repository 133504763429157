.Dashboard{
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.Dashboard .container{
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 5px 2px 5px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Dashboard input{
    border: 2px dashed black;
}

.ml10{
    margin-left: 10px;
}

.mr3{
    margin-right: 3px;
}

.Dashboard h1{
    font-family: 'Lucida Console', monospace;
    font-size: 28px;
    line-height: 27px;
}

.Dashboard h2{
    font-family: 'Lucida Console', monospace;
    font-size: 20px;
    line-height: 21px;
}

.Dashboard h3{
    font-family: 'Lucida Console', monospace;
    font-size: 17px;
    line-height: 18px;
}

.Dashboard h4{
    font-family: 'Lucida Console', monospace;
    font-size: 14px;
    line-height: 15px;
}

.Dashboard p{
    font-family: 'Lucida Console', monospace;
    font-size: 13px;
    line-height: 14px;
}

.title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.title-container h1{
    margin-bottom: 10px;
}

.body-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    
}

.single-box{
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.single-box h3{
    margin-right: 3px;
}